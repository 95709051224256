import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate, Link } from "react-router-dom";
import WalletModal from "../common/WalletModal";
import Modal from "react-bootstrap/Modal";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useAccount } from "wagmi";

function ReferralModal(props) {
  const { address } = useAccount();
  const handleCopy = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      toast.success("Address copied to clipboard!", {});
    }
  };
  return (
    <Modal
      className="wallet-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <IoCloseCircleOutline onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className="px-5">
        <div className="wallet-main referral-main">
          <h4>Referral Link</h4>
          <p>
            Share the link below with your friends and earn 5% from each
            purchase they make.
          </p>

          <div className="form">
            <div>
              {address ? (
                <h4>{address}</h4>
              ) : (
                <h4>Please connect your wallet to access your address</h4>
              )}
            </div>
            <div>
              <button className="btn" onClick={handleCopy} disabled={!address}>
                Copy
              </button>
            </div>
          </div>

          {/* <div class="form">
            <div>
              <h4>{address}</h4>
              <h4>Please connect your wallet to access your address</h4>
            </div>
            <div>
              <button class="btn">Copy</button>
            </div>
          </div> */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <p className="m-0">Share your link on</p>
            </div>
            <div>
              <ul class="list-inline d-flex p-0 m-0">
                {/* <li>
                  <a href="#/">
                    <img src="/images/Discord.png"></img>
                  </a>
                </li> */}
                <li>
                  <a href="https://x.com/PlayappEconomy">
                    <img src="/images/Twitter.png"></img>
                  </a>
                </li>
                <li>
                  <a href="https://t.me/+2El92idV0yBjZmM0">
                    <img src="/images/Telegram.png"></img>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Header = () => {
  const [modalShow, setModalShow] = useState(false);

  const files = [
    { name: "PlayAppWhitepaper", url: "/whitepaper/PlayAppWhitepaper.pdf" },
    { name: "playappcoinv3", url: "/whitepaper/playappcoinv3.pdf" },
  ];
  const downloadFiles = () => {
    files.forEach((file) => {
      const link = document.createElement("a");
      link.href = file.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <>
     <ToastContainer />
      <ReferralModal show={modalShow} onHide={() => setModalShow(false)} />
      <Navbar fixed="top" expand="lg" className="custom-header destkop">
        <Container>
          <div className="header">
            <Navbar.Brand>
              <Link to="/">
                <img src="/images/logo.png" alt="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to="/" className="nav-link">
                  Home
                </Link>
                <Link
                  to=""
                  onClick={() => setModalShow(true)}
                  className="nav-link"
                >
                  Referral
                </Link>
                <Link to="/howwork" className="nav-link">
                  How it works
                </Link>
                {/* <Link onClick={downloadFiles} to="" className="nav-link">
                  Whitepaper
                </Link> */}
              </Nav>
              <div className="gap-md d-flex align-items-center ">
                <WalletModal />
                {/* <Link to="/buy" className="btn btn-outline">
                <img src="/images/wallet.png" />
                CONNECT
              </Link> */}
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

      {/* Majid Header */}

      {/* <div className="navbar navbar-expand-lg navbar-light ">
        <div className="container">
          <Navbar expand="lg">
            <Container>
              <div className="header">
                <div className="justify-content-between d-flex ">
                  <Navbar.Brand href="/">
                    <img src="/images/logo.png" alt="logo" />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mx-auto">
                    <Nav.Link href="#whychooseus">Why Choose Us</Nav.Link>
                    <Nav.Link href="#ourmission">Our Mission</Nav.Link>
                    <Nav.Link href="#service">Service</Nav.Link>
                    <Nav.Link
                      href="/whitepaper/playapp-v2.docx"
                      target="_blank"
                    >
                      Whitepaper
                    </Nav.Link>
                    <Nav.Link href="#blog">Blog</Nav.Link>
                    <Nav.Link href="#partner">Partner</Nav.Link>

                    <div className="mobile">
                      <button
                        className="header-button"
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        BUY
                      </button>
                    </div>
                  </Nav>
                </Navbar.Collapse>
                <div className="desktop">
                  <button
                    className="header-button"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    BUY NOW
                  </button>
                </div>
              </div>
            </Container>
          </Navbar>
        </div>
      </div> */}
    </>
  );
};

export default Header;
