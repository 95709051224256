import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoCloseCircleOutline } from "react-icons/io5";

function MyVerticallyCenteredModal(props) {
  const [uniSatConnected, setUniSatConnected] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState({});
  const API_KEY = 1234;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    transactionHash: "",
  });

  // const [nameValue, setNameValue] = useState("");
  // const [emailValue, setEmailValue] = useState("");
  // const [phoneValue, setPhoneValue] = useState("");
  const BtcValue = "60000";

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }
    return newErrors;
  };

  const handleConnectUniSat = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    try {
      if (typeof window.unisat !== "undefined") {
        console.log("UniSat Wallet is installed!");

        let accounts = await window.unisat.requestAccounts();
        console.log("connect success", accounts);

        let getAccounts = await window.unisat.getAccounts();
        console.log("Get Accounts", getAccounts);

        let getChain = await window.unisat.getChain();
        console.log("Get Chain", getChain);

        localStorage.setItem("chainName", getChain.name);
        localStorage.setItem("chainId", getAccounts);

        const toAddress = "tb1q9xl87jv96jk9u99d7lghldzze2u0302fzmqsrx"; // replace with the actual receiver's address
        const amountToSend = inputValue; // Amount to transfer, should be in satoshis or the smallest unit of BTCmo
        console.log("value", amountToSend);
        // const transaction = await window.unisat.sendBitcoin({
        // to: toAddress,
        // value: parseInt( amountToSend * 1e8),

        // });
        let txid = await window.unisat.sendBitcoin(
          toAddress,
          parseFloat(amountToSend) * 1e8
        );
        console.log("ToAddress", toAddress);
        console.log("Transaction success:", txid);

        setFormData({
          ...formData,
          transactionHash: txid,
        });

        const response = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/savebtctransection`,
          {
            data: formData,
          },
          {
            headers: {
              apikey: ` ${API_KEY}`,
            },
          }
        );
        console.log("formdata", response);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          transactionHash: txid,
        });

        // setUniSatConnected(true);
        // Close the modal
        props.onHide();
      }
    } catch (error) {
      console.error("Error connecting to UniSat wallet:", error);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
    setErrors({
      ...errors,
      phone: "",
    });
  };

  return (
    <Modal
      className="wallet-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      <Modal.Header>
        <IoCloseCircleOutline onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className="px-5">
        <div className="wallet-main">
          <h4>Connect Your Wallet</h4>
          <p>Hello founder! Connect your BTC wallet to start the application</p>

          <section className="Hero dashboardhero wallethero">
            <div className="calculator">
              <div className="content">
                <form className="calculateform">
                  <div className="row p-0">
                    <div className="col-6">
                      <div className="form-group mb-3">
                        <div className="form">
                          <div className="w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              onChange={handleFormChange}
                              name="firstName"
                              value={formData.firstName}
                            />
                          </div>
                        </div>
                        {errors.firstName && (
                          <div
                            className="error mt-3"
                            style={{ color: "#721c24" }}
                          >
                            {errors.firstName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group mb-3">
                        <div className="form">
                          <div className="w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={handleFormChange}
                              name="lastName"
                              value={formData.lastName}
                            />
                          </div>
                        </div>
                        {errors.lastName && (
                          <div
                            className="error mt-3"
                            style={{ color: "#721c24" }}
                          >
                            {errors.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="form">
                      <div className="w-100">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onChange={handleFormChange}
                          name="email"
                          value={formData.email}
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <div className="error mt-3" style={{ color: "#721c24" }}>
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-3">
                    <div className="form">
                      <div className="w-100">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          onChange={handleFormChange}
                          name="phone"
                          value={formData.phone}
                        /> */}

                        <PhoneInput
                          country={"pk"}
                          value={formData.phone}
                          onChange={handlePhoneChange}
                        />
                      </div>
                    </div>
                    {errors.phone && (
                      <div className="error mt-3" style={{ color: "#721c24" }}>
                        {errors.phone}
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <div className="form-heading">
                      <label>I want to spent</label>
                    </div>
                    <div className="form">
                      <div className="w-100">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter amount"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="select">
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            style={{ justifyContent: "end" }}
                          >
                            <div className="dropdown-selected">
                              <img src="/images/btc-log.png" alt="USDT" />
                              <span>BTC</span>
                              {/* <i className="dropdown-icon ">
                                <img src="/images/calculatedrop.png" />
                              </i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-heading">
                      <label>I will receive</label>
                      <label>1 USDT = 0.05 PLAP </label>
                    </div>
                    <div className="form">
                      <div className="w-100">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Limit 10 - 5000"
                          defaultValue={0}
                          value={inputValue}
                        />
                      </div>
                      <div className="select">
                        <img src="/images/playpcircle.png" />
                      </div>
                    </div>
                  </div>
                  <div className="calculate-btn mb-5">
                    <button
                      type="button"
                      onClick={handleConnectUniSat}
                      className="btn form-control"
                    >
                      Buy PLAP
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Sidebar = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isOpen, setIsOpen] = useState(true); // Open by default

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false); // Close sidebar on smaller screens
      } else {
        setIsOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const files = [
    { name: "PlayAppWhitepaper", url: "/whitepaper/PlayAppWhitepaper.pdf" },
    { name: "playappcoinv3", url: "/whitepaper/playappcoinv3.pdf" },
  ];
  const downloadFiles = () => {
    files.forEach((file) => {
      const link = document.createElement("a");
      link.href = file.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        // setUniSatConnected={setUniSatConnected}
      />
      <button className="toggle-button" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <div className="sidebar-content">
          <div className="logo text-center pt-3">
            <Link to="/">
              <img src="/images/logo.png" className="pb-2" alt="logo" />
            </Link>
            <img src="/images/divide-logo.png" alt="divide" />
          </div>
          <ul>
            <li className="active">
              <Link to="">
                <img src="/images/homeadmin.png" />
                Dashboard
              </Link>
            </li>

            {/* <li>
              <Link to="" onClick={() => setModalShow(true)}>
                <img src="/images/wallet.png" />
                Buy Using BTC
              </Link>
            </li> */}

            <li>
              <Link onClick={downloadFiles} to="">
                <img src="/images/homewhite.png" />
                Whitepaper
              </Link>
            </li>
          </ul>
          <div className="documentation">
            <img src="/images/help.png" />
            <h5 className="pt-3">Need help?</h5>
            <p className="pb-3 pt-2">Please check our docs</p>
            <Link to="mailto:support@playapptoken.io">Contact us</Link>
          </div>
        </div>
      </div>
      {/* <div className="side-bar">
        <div className="side-bar-inner">
          <div className="text-center pt-2 pb-4">
            <img src="/images/logo.png" alt="logo" />
          </div>
          <img src="/images/divide-logo.png" alt="divide" />
          <ul className="mt-5">
            <li>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2374_730)">
                  <path
                    d="M6.96211 3.0564C6.92437 3.0203 6.87416 3.00015 6.82194 3.00015C6.76971 3.00015 6.7195 3.0203 6.68177 3.0564L2.01527 7.51428C1.99546 7.53324 1.97969 7.55602 1.96893 7.58125C1.95817 7.60647 1.95263 7.63362 1.95266 7.66104L1.9519 11.8449C1.9519 12.06 2.03736 12.2664 2.18948 12.4185C2.34159 12.5706 2.5479 12.656 2.76303 12.656H5.19893C5.30649 12.656 5.40965 12.6133 5.48571 12.5373C5.56176 12.4612 5.60449 12.358 5.60449 12.2505V8.80321C5.60449 8.74943 5.62586 8.69785 5.66389 8.65982C5.70191 8.62179 5.75349 8.60043 5.80727 8.60043H7.83508C7.88886 8.60043 7.94044 8.62179 7.97847 8.65982C8.0165 8.69785 8.03786 8.74943 8.03786 8.80321V12.2505C8.03786 12.358 8.08059 12.4612 8.15665 12.5373C8.23271 12.6133 8.33586 12.656 8.44342 12.656H10.8783C11.0934 12.656 11.2997 12.5706 11.4519 12.4185C11.604 12.2664 11.6894 12.06 11.6894 11.8449V7.66104C11.6895 7.63362 11.6839 7.60647 11.6732 7.58125C11.6624 7.55602 11.6466 7.53324 11.6268 7.51428L6.96211 3.0564Z"
                    fill="#A9FF8A"
                  />
                  <path
                    d="M12.7759 6.6783L10.8799 4.86443V2.11193C10.8799 2.00437 10.8371 1.90122 10.7611 1.82516C10.685 1.7491 10.5819 1.70637 10.4743 1.70637H9.25763C9.15006 1.70637 9.04691 1.7491 8.97085 1.82516C8.89479 1.90122 8.85206 2.00437 8.85206 2.11193V2.92306L7.38393 1.51931C7.24655 1.3804 7.04225 1.30081 6.82147 1.30081C6.60145 1.30081 6.39766 1.3804 6.26027 1.51956L0.86884 6.6778C0.711178 6.82988 0.691407 7.08006 0.834875 7.24482C0.870901 7.28641 0.915014 7.32023 0.964527 7.34423C1.01404 7.36823 1.06792 7.3819 1.12288 7.38441C1.17785 7.38693 1.23275 7.37823 1.28425 7.35885C1.33574 7.33947 1.38276 7.30982 1.42243 7.27169L6.68206 2.24577C6.71979 2.20967 6.77001 2.18952 6.82223 2.18952C6.87445 2.18952 6.92466 2.20967 6.9624 2.24577L12.2225 7.27169C12.3 7.346 12.4038 7.38655 12.5111 7.38446C12.6185 7.38237 12.7206 7.33781 12.7951 7.26054C12.9508 7.09933 12.9378 6.83318 12.7759 6.6783Z"
                    fill="#A9FF8A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2374_730">
                    <rect
                      width="12.978"
                      height="12.978"
                      fill="white"
                      transform="translate(0.332275 0.488922)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Dashboard
            </li>
          </ul>
          <div className="documentation">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.14502"
                y="0.68631"
                width="32.1262"
                height="32.1262"
                rx="11.0147"
                fill="#06091B"
              />
              <path
                d="M16.208 8.48834C11.6473 8.48834 7.94702 12.1886 7.94702 16.7494C7.94702 21.3101 11.6473 25.0104 16.208 25.0104C20.7688 25.0104 24.4691 21.3101 24.4691 16.7494C24.4691 12.1886 20.7688 8.48834 16.208 8.48834ZM15.9499 21.5683C15.7797 21.5683 15.6133 21.5178 15.4718 21.4233C15.3303 21.3287 15.22 21.1943 15.1549 21.0371C15.0897 20.8798 15.0727 20.7068 15.1059 20.5399C15.1391 20.373 15.2211 20.2196 15.3414 20.0993C15.4618 19.9789 15.6151 19.897 15.782 19.8638C15.9489 19.8306 16.122 19.8476 16.2792 19.9127C16.4364 19.9779 16.5708 20.0882 16.6654 20.2297C16.7599 20.3712 16.8104 20.5376 16.8104 20.7078C16.8104 20.936 16.7197 21.1549 16.5584 21.3163C16.397 21.4776 16.1781 21.5683 15.9499 21.5683ZM17.3887 17.1796C16.6912 17.6477 16.5953 18.0767 16.5953 18.4704C16.5953 18.6302 16.5318 18.7834 16.4188 18.8963C16.3059 19.0093 16.1527 19.0728 15.9929 19.0728C15.8332 19.0728 15.6799 19.0093 15.567 18.8963C15.454 18.7834 15.3905 18.6302 15.3905 18.4704C15.3905 17.5277 15.8242 16.7782 16.7166 16.1788C17.5462 15.6221 18.0151 15.2693 18.0151 14.4935C18.0151 13.966 17.714 13.5654 17.0905 13.269C16.9438 13.1993 16.6172 13.1313 16.2154 13.136C15.7111 13.1425 15.3196 13.263 15.0179 13.5056C14.4491 13.9634 14.4009 14.4617 14.4009 14.469C14.3971 14.5481 14.3778 14.6257 14.344 14.6973C14.3102 14.7689 14.2626 14.8332 14.204 14.8864C14.1453 14.9396 14.0768 14.9808 14.0023 15.0076C13.9277 15.0343 13.8486 15.0461 13.7695 15.0423C13.6904 15.0385 13.6129 15.0191 13.5412 14.9853C13.4696 14.9515 13.4053 14.904 13.3521 14.8453C13.2989 14.7867 13.2577 14.7182 13.231 14.6436C13.2042 14.5691 13.1924 14.49 13.1962 14.4109C13.2009 14.3063 13.2737 13.3645 14.262 12.5694C14.7744 12.1572 15.4263 11.9429 16.1981 11.9334C16.7446 11.927 17.2579 12.0195 17.606 12.1839C18.6476 12.6765 19.2199 13.4979 19.2199 14.4935C19.2199 15.9491 18.2471 16.6026 17.3887 17.1796Z"
                fill="#A9FF8A"
              />
            </svg>
            <h5 className="pt-3">Need help?</h5>
            <p className="pb-3 pt-2">Please check our docs</p>
            <button>Documentation</button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Sidebar;
