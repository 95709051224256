import React from "react";
import LaunchCard from "../common/LaunchCard";

const HowToBuy = () => {
  return (
    <div className="wrapper">
      <section className="HowWorks">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="left-content">
                <h1>How it Works</h1>
                <h4>
                  <span>1</span>Set E Wallet
                </h4>
                <div className="content">
                  <h4>
                    <img src="/images/mobile.png" />
                    Install and Setup Metamask
                  </h4>
                  <p>
                    Download MetaMask, follow the instructions to create a new
                    wallet or import an existing one, and securely back up your
                    seed phrase.
                  </p>
                  <h4>
                    <img src="/images/destkop.png" />
                    Fund your Metamask Wallet
                  </h4>
                  <p>
                    Click on "Add Funds" and choose the method to deposit ETH,
                    USDT, and BNB. You can transfer from another wallet or buy
                    using a credit/debit card.
                  </p>
                </div>
                <h4>
                  <span>2</span>Connect E Wallet with Playapp
                </h4>
                <div className="content">
                  <p>
                    Click on "Connect Wallet" in the top right corner. Select
                    the number of $PLAP tokens you want to buy from and the
                    Playapp.
                  </p>
                  <p className="mb-5">
                    Follow the instructions to connect your E Wallet with
                    Playapp.
                  </p>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Connect Wallet
                  </h5>
                  <ul>
                    <li>Look for top right connect wallet option</li>
                    <li>A prompt will appear with a list of wallets</li>
                    <li>Choose wallet you want to connect</li>
                  </ul>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Verify Your Wallet Connection
                  </h5>
                  <ul>
                    <li>See your wallet address in the Playapp dashboard</li>
                    <li>Go to profile to confirm that your wallet is linked</li>
                    <li>
                      Review any additional settings or information related to
                      your account and wallet
                    </li>
                  </ul>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Participate in the PLAP Coin Presale
                  </h5>
                  <ul>
                    <li>Navigate to the Presale Card section</li>
                    <li>Card will have all Presale information</li>
                    <li>Enter the amount of PLAP you wish to buy</li>
                  </ul>
                </div>

                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Buy PLAP Coins
                  </h5>
                  <ul>
                    <li>Click the Buy button</li>
                    <li>Confirm the transaction details</li>
                    <li>Check network fee </li>
                    <li> Confirm the transaction</li>
                    <li>Review the details carefully and Confirm</li>
                    <li>
                      Wait for the transaction processed on the blockchain and
                      this can take a few moments
                    </li>
                  </ul>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Verify Your PLAP Coin
                  </h5>
                  <ul>
                    <li>After the transaction go back to your wallet </li>
                    <li>Check your tokens </li>
                    <li>PLAP coins should appear in your wallet</li>
                    <li>Use PLAP contract address to add the token manually</li>
                    <li>
                      You will receive transaction history in Dashboard right
                      after My Wallet{" "}
                    </li>
                  </ul>
                </div>
                <h4>
                  <span>3</span>Hold & Reward
                </h4>
                <div className="content">
                  <p>
                    Once you have purchased the PLAP token, hold it in your
                    wallet. Playapp offers additional earning opportunities such
                    as Hold Coin to Earn More.
                  </p>
                </div>
                <h4>
                  <span>4</span>Security
                </h4>
                <div className="content">
                  <p>
                    Always verify you are on the official Playapp website and
                    interacting with the correct smart contracts
                  </p>
                </div>
                <h4>
                  <span>5</span>Transaction Fee
                </h4>
                <div className="content">
                  <p>
                    Be aware of network fees for transactions on Ethereum, USDT,
                    and BNB
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-12">
              <div className="right-content">
                <div className="imglist">
                  <img src="/images/work1.png" style={{marginBottom:"164px", marginTop:"123px"}} className="w-100" />
                  <img src="/images/work2.png" style={{marginBottom:"129px"}} className="w-100" />
                  <img src="/images/work3.png" style={{marginBottom:"120px"}} className="w-100" />
                  <img src="/images/work4.png" style={{marginBottom:"71px"}} className="w-100" />
                  <img src="/images/work5.png" style={{marginBottom:"50px"}} className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowToBuy;
