import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
const Teams = () => {
  return (
    <div className="teams" id="faq">
      {/* <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <h4>Meet The Masterminds Behind $PLAP</h4>
              <h3 style={{color:"white"}}>  Made by sports and crypto enthusiasts, for crypto enthusiasts and athletes ! </h3>
              <p>
             

              A finance visionary, a corporate expert, a professional athlete and a Blockchain specialist 
              unite a shared passion for the dynamic world of web3 and sports. 
              Each of us contributed something special, and when we joined forces, 
              PlayApp success became inevitable.


              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1500">
            <div style={{ position: "relative" }} className="teams-cards">
              <div>
                <img src="/images/team1.png" />
              </div>
              <div className="title">
                <h4>Natey adjei</h4>
                <p>CEO & Director</p>
              </div>
              <div className="social">
                <ul className="list-unstyled m-0">
                  <li>
                    <img src="/images/facebook.png" />
                  </li>
                  <li>
                    <img src="/images/twit.png" />
                  </li>
                  <li>
                    <img src="/images/in.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1500">
            <div style={{ position: "relative" }} className="teams-cards">
              <div>
                <img src="/images/team2.png" />
              </div>
              <div className="title">
                <h4>Hamza Yasin</h4>
                <p>CTO - Blockchain Advisor</p>
              </div>
              <div className="social">
                <ul className="list-unstyled m-0">
                  <li>
                    <img src="/images/facebook.png" />
                  </li>
                  <li>
                    <img src="/images/twit.png" />
                  </li>
                  <li>
                    <img src="/images/in.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1500">
            <div style={{ position: "relative" }} className="teams-cards">
              <div>
                <img src="/images/team1.png" />
              </div>
              <div className="title">
                <h4>Natey adjei</h4>
                <p>CEO & Director</p>
              </div>
              <div className="social">
                <ul className="list-unstyled m-0">
                  <li>
                    <img src="/images/facebook.png" />
                  </li>
                  <li>
                    <img src="/images/twit.png" />
                  </li>
                  <li>
                    <img src="/images/in.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1500">
            <div style={{ position: "relative" }} className="teams-cards">
              <div>
                <img src="/images/team2.png" />
              </div>
              <div className="title">
                <h4>Imran Siddiqui</h4>
                <p>CFO & Director</p>
              </div>
              <div className="social">
                <ul className="list-unstyled m-0">
                  <li>
                    <img src="/images/facebook.png" />
                  </li>
                  <li>
                    <img src="/images/twit.png" />
                  </li>
                  <li>
                    <img src="/images/in.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="faqs mt-0">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="content">
                <h2 className="pb-2">FAQ's</h2>
                <p>
                  Find answers to common queries about Playapp. In case we
                  missed something, Live & Email support is always here!
                </p>
                <a href="mailto:support@playapptoken.io" className="contact">Contact Us</a>
              </div>
            </div>
            <div
              className="col-lg-8 pt-5 pt-lg-0"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <Accordion className="faq-accordion" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                  What can I do with $PLAP tokens?
                  </Accordion.Header>
                  <Accordion.Body>
                  $PLAP tokens can be used for various purposes within the PlayApp ecosystem, including access our sports AI model software and utilities, purchasing NFTs, accessing premium features, participating in governance decisions through the PlayApp DAO, promote profiles, buy event tickets, purchase merchandise, and much more.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                  How does PlayApp's Play-to-Earn model work?
                  </Accordion.Header>
                  <Accordion.Body>
                  Users earn rewards in the form of "Scores" for participating in real-world sports and fitness activities. These "Scores" can then be converted into $PLAP tokens, creating a direct link between physical activity and digital earnings.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                  What's PlayApp's global expansion strategy?
                  </Accordion.Header>
                  <Accordion.Body>
                  PlayApp aims to expand into key markets including North America, Europe, Middle East, Asia, Africa, and South America, with a goal of reaching 1 billion users.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                  What is the PlayApp DAO?
                  </Accordion.Header>
                  <Accordion.Body>
                  The PlayApp DAO (Decentralized Autonomous Organization) allows $PLAP holders to participate in the governance of the platform, including voting on proposals and contributing to the ecosystem's development.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
