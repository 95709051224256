import invoke from '../utils/invoke';

const apikey = process.env.REACT_APP_API_KEY;


export const saveTransaction = async (transactionData) => {
    const data = await invoke({ url: `/saveTransection`, method: 'POST', headers: {
        apikey,
    }, data: transactionData  });
    return data;
}


export const getContractInfo = async () => {
    const data = await invoke({ url: `/getContractInfo`, method: 'GET', headers: {
        apikey,
    }});
    return data;
}

