import React from "react";
import { useInView } from "react-intersection-observer";
const Tokenomics = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  return (
    <>
      <section
      id="tokenomics"
        ref={ref}
        className={`Tokenomics ${inView ? "showcontent" : ""}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="content">
                <div className="left-content">
                  <div className="txt">
                    <h4>Tokenomics</h4>
                    <p>
                      8 Billion Total Tokens. No Tax. With Deflationary Concept.
                    </p>
                    <h5>
                      Token Contract Address
                      <span>(<a href="https://bscscan.com/token/0x3a652479A40d1A866893D9a4281cAd731774A44d" target="_blank">0x3a6….4A44d</a>)</span>
                    </h5>
                  </div>
                  <ul className="list-inline">
                    <li className="ecommerce">
                      <div>
                        <h3>User Rewards & Incentives</h3>
                        <label>25%</label>
                      </div>
                      <div>
                        <h4>2.0</h4>
                        <h5>Billion</h5>
                      </div>
                    </li>
                    <li className="partnership">
                      <div>
                        <h3>Pre-Sale</h3>
                        <label>20%</label>
                      </div>
                      <div>
                        <h4>1.6</h4>
                        <h5>Billion</h5>
                      </div>
                    </li>
                    <li className="fund">
                      <div>
                        <h3>Treasury</h3>
                        <label>15%</label>
                      </div>
                      <div>
                        <h4>1.2</h4>
                        <h5>Billion</h5>
                      </div>
                    </li>
                    <li className="advisory">
                      <div>
                        <h3>Team & Advisory</h3>
                        <label>15%</label>
                      </div>
                      <div>
                        <h4>1.2</h4>
                        <h5>Billion</h5>
                      </div>
                    </li>
                    <li className="growth">
                      <div>
                        <h3>Research & Development</h3>
                        <label>15%</label>
                      </div>
                      <div>
                        <h4>1.2</h4>
                        <h5>Billion</h5>
                      </div>
                    </li>
                    <li className="reserve">
                      <div>
                        <h3>Marketing & Partnerships</h3>
                        <label>10%</label>
                      </div>
                      <div>
                        <h4>800</h4>
                        <h5>Million</h5>
                      </div>
                    </li>
                    {/* <li className="sale">
                      <div>
                        <h3>Public Sale</h3>
                        <label>20%</label>
                      </div>
                      <div>
                        <h4>1.6</h4>
                        <h5>Billion</h5>
                      </div>
                    </li> */}
                  </ul>
                </div>
                <div className="right-content">
                  <div className="circle-content">
                    <img src="/images/graphcircle.png" />
                    <div className="ecommerce">
                      <span>25%</span>
                      User Rewards & Incentives
                    </div>
                    {/* <div className="sale">
                      <span>20%</span>
                      Public Sale
                    </div> */}
                    <div className="reserve">
                      <span>10%</span>
                      Marketing & Partnerships
                    </div>
                    <div className="growth">
                      <span>15%</span>
                      Research & Development
                    </div>
                    <div className="advisory">
                      <span>15%</span>
                      Team & Advisory
                    </div>
                    <div className="fund">
                      <span>15%</span>
                      Treasury
                    </div>
                    <div className="partner">
                      <span>20%</span>
                      Pre-Sale
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="roadmap">
          <div className="row">
            <div className="col-12">
              <div className="Roadmap">
                <div className="image">
                  <img src="/images/roadmapback.png" />
                </div>
                <div className="roadmap-content">
                  <div className="heading">
                    <h4>Roadmap</h4>
                    <p>
                      Empowering Play, Pioneering Possibilities: The Roadmap to
                      Sports & Fitness Excellence.
                    </p>
                  </div>
                  <ul className="timeline list-inline">
                    <li
                      className="q1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 1</h4>
                      <p>$PLAP Pre-Sale Launch</p>
                      <p>Complete Pre-Sale</p>
                      <p>Add Liquidity & List $PLAP on Major Dex’s</p>
                      <p>Launch PlayApp Token Wallet</p>
                      <p>Integrate PlayApp Economy</p>
                    </li>
                    <li
                      className="q2"
                      data-aos="fade-down"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 2:</h4>
                      <p>Onboard Professional Influencers & Athletes</p>
                      <p>Launch $1M Marketing Campaign</p>
                      <p>Build Social Community with Rewards</p>
                      <p>Launch Play-to-Earn model by increasing fitness participation</p>
                      <p>Initiate PlayApp DAO</p>
                      <p>List $PLAP on Major Exchanges</p>
                    </li>
                    <li
                      className="q3"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 3:</h4>
                      <p>Introduce P2E Gaming</p>
                      <p>List PlayApp Token on All Major Exchanges</p>
                      <p>Onboard Social E-gaming Influencers</p>
                      <p>Launch $5M Marketing Campaign</p>
                      <p>Onboard sports organizations and corporate teams</p>
                      <p>Initialize Advertising & Sponsorships with $PLAP </p>
                    </li>
                    <li
                      className="q4"
                      data-aos="fade-down"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 4:</h4>
                      <p>Launch NFT marketplace </p>
                      <p>Deploy AI sports & fitness agents</p>
                      {/* <p>Introduce Social Metaverse with AR/VR Games</p> */}
                      <p>Initiate blockchain development</p>
                      <p>Additional token utilities</p>
                    </li>
                    {/* <li
                      className="q5"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 5:</h4>
                      <p>
                        Introduce Social Metaverse
                      </p>

                      <p>
                        Implement cooperate sports & fitness partnership.
                      </p>

                      <p>
                        Add Additional Utilities.
                      </p>
                      
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tokenomics;
