import { Diversity1Outlined } from "@mui/icons-material";
import React from "react";

const AboutPlap = () => {
  return (
    <>
      <section className="AboutPlap" id="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="whatplap">
                <div className="img">
                  <img src="/images/whatplap.png" />
                </div>
                <div className="content">
                  <h4>What is $PLAP?</h4>
                  <p>
                  $PLAP is a game-changing cryptocurrency powering the next-gen convergence of sports, entertainment, and blockchain technology. This cutting-edge token is the backbone of the PlayApp ecosystem, a decentralized platform that's revolutionizing how we engage with athletics in the digital age.

At its core, $PLAP harnesses the power of AI and smart contracts to transform physical prowess into digital gold. Whether you're crushing it in e-sports tournaments, breaking records on the track, or dominating in fantasy leagues, your achievements are instantly tokenized into tangible digital assets. This innovative approach gamifies fitness, incentivizes athletic excellence, and democratizes the sports economy like never before.
                  {/* $PLAP is a revolutionary token designed to integrate real-world sports and fitness activities into a digital ecosystem, providing users with a rewarding and personalized experience through an advanced blockchain platform. */}
                    {/* $PLAP is a revolutionary token designed to integrate
                    real-world sports and fitness facilities into a digital
                    ecosystem, providing users with a rewarding and personalized
                    experiences through an advanced AI. */}
                  </p>

                  {/* <button className="btn">Buy PLAP</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="rewardlist">
            <div
              class="listone"
              data-aos="fade-right"
              data-aos-duration="250"
              data-aos-offset="-150"
            >
              <div>
                <img src="/images/pwhite.png" className="mb-5" />
              </div>
              <h3>
                Tokenizing Sports Activities
              </h3>
              <p>
              PlayApp Token ($PLAP) revolutionizes the sports and fitness economy by tokenizing real-world activities. Users earn "Scores" based on participation in sports, fitness challenges, and community events. These Scores represent tangible value within the PlayApp ecosystem, incentivizing active lifestyles and engagement.
<br></br><br></br>
The PlayApp platform seamlessly integrates various aspects of the sports industry, including facility bookings, e-sports, live streaming, and event ticketing. By converting physical activities into digital rewards, $PLAP creates a unique bridge between real-world sports participation and the digital economy, fostering a community centered around shared sports interests and healthy lifestyles
              </p>
              <div>
              </div>
            </div>
            <div class="listtwo">
              <div
                data-aos="fade-up"
                data-aos-duration="250"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Play-to-Earn Model</h3>
                </div>
                <p>
                Earn rewards while using real-world sports facilities. $PLAP serves as the native currency for the PlayApp ecosystem, powering transactions and incentivizing participation.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>NFT Integration</h3>
                </div>
                <p>
                Unique digital assets representing sports memorabilia, achievements, and exclusive experiences.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1400"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Community Governance</h3>
                </div>
                <p>
                Participate in the PlayApp DAO to shape the future of the platform and vote on key decisions.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1600"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Redeem and Burn</h3>
                </div>
                <p>
                Users can redeem earned PlayApp 'Scores' for $PLAP tokens, with an equal amount burned to manage supply.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1800"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Global Expansion</h3>
                </div>
                <p>
                Targeting key markets including North America, Europe, Middle East, Asia, Africa, and South America.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-offset="-150"
                class="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Blockchain Integration</h3>
                </div>
                <p>
                Secure, transparent, and interoperable with other cryptocurrencies and blockchain ecosystems.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div data-aos="fade-up" data-aos-duration="800">
                <div className="Rewards">
                  <div className="txt">
                    <h4>Gamified Eco-system</h4>
                    <p>
                      We believe in creating an socially engaging sports
                      ecosystem where every player is rewarded for their
                      involvement and contributions ensuring that both casual
                      and dedicated community members can benefit from holding
                      and using $PLAP.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div data-aos="fade-up" data-aos-duration="900">
                <div className="Rewards">
                  <div className="txt">
                    <h4>Real World Sports</h4>
                    <p>
                      We are bridging the gap between the digital and physical
                      worlds by integrating and tokenizing real-world sports and
                      fitness facilities and gameplay. This innovative approach
                      brings tangible value to your digital holdings making it
                      secure and impactful in the real world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="Rewards">
                  <div className="txt">
                    <h4>Personalized AI </h4>
                    <p>
                      We harness advanced AI to tailor your experience, ensuring
                      every interaction is personalized to your preferences and
                      needs. Our AI agents analyze your preferences to deliver a
                      customized user experience with personalized dashboards
                      and curated insights.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default AboutPlap;
