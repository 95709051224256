import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
//   const handleScroll = (sectionId) => {
//     document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
// };



const handleScroll = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};


  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <ul className="list-inline">
                <li>
                  <Link onClick={() => handleScroll('about')} to="/">About Us</Link>
                </li>
                <li>
                  <Link to="/howwork">How it works</Link>
                </li>
                <li>
                  <Link onClick={() => handleScroll('tokenomics')} to="/">Tokenomics</Link>
                </li>
                <li>
                  <Link onClick={() => handleScroll('roadmap')} to="/">Roadmap</Link>
                </li>
                <li>
                  <Link onClick={() => handleScroll('faq')} to="/">Faq's</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <img src="/images/logo.png" alt="logo" />
            </div>
            <div className="col-lg-5">
              <ul className="list-inline">
                <li>Follow Us:</li>
                {/* <li>
                  <Link to="">
                  <img src="/images/Discord.png" />
                  Discord
                  </Link>
                </li> */}
                <li>
                  <Link to="https://x.com/PlayappEconomy"><img src="/images/Twitter.png" />Twitter</Link>
                </li>
                <li>
                  <Link to="https://t.me/+2El92idV0yBjZmM0"><img src="/images/Telegram.png" />Telegram</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
