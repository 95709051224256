import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useAccount, useConnect, useEnsAvatar, useEnsName } from "wagmi";
import { IoCloseCircleOutline } from "react-icons/io5";

function MyVerticallyCenteredModal(props) {
  const { connectors, connect } = useConnect();

  // console.log(connectors, "connectors");

  const handleConnect = (connector) => {
    connect({ connector });
    props.onHide(); // Hide the modal after connecting
  };
  return (
    <Modal
      className="wallet-modal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
      <IoCloseCircleOutline onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className="px-5">
        <div className="wallet-main">
          <h4>Connect Your Wallet</h4>
          <p>
            Connect your wallet to invest in the worlds first blockchain based sports ecosystem
          </p>
          {connectors.map((connector) => (
            <div
              className="desktop my-4"
              style={{ width: "100%" }}
              key={connector.uid}
              onClick={() => handleConnect(connector)}
            >
              <button className="header-button" style={{ width: "100%" }}>
                <div style={{ fontSize: "18px" }}>
                  {connector.name === "MetaMask" ? (
                    <img
                      style={{ width: "35px", marginRight: "10px" }}
                      src="/images/metamask-fox.svg"
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "35px", marginRight: "10px" }}
                      src="/images/walletconnect.png"
                      alt=""
                    />
                  )}
                  {connector.name}
                </div>
                <div className="txt">Connect</div>
              </button>
            </div>
          ))}

        

          <p className="m-0">New to PLAP</p>
          <Link to="/howwork">Learn more how it works.</Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function WalletModal() {
  const [modalShow, setModalShow] = useState(false);
  const { isConnected } = useAccount();

  return (
    <>
      {!isConnected ? (
        <button id={'connect-btn'} className="btn btn-outline" onClick={() => setModalShow(true)}>
          <img src="/images/wallet.png"></img>
          CONNECT
        </button>
      ) : (
        <Link to={"/buy"} className="btn btn-outline">
          <img src="/images/wallet.png" />
          GO TO BUY
        </Link>
      )}

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
