import { http, createConfig } from 'wagmi'
import { base, bsc, bscTestnet, sepolia, arbitrumSepolia } from 'wagmi/chains'
import { walletConnect } from 'wagmi/connectors'
import { chains } from './chains';

const projectId = "8cff1ce28d43de0aef36256380727538"

export const config = createConfig({
  chains: chains,
  connectors: [
    walletConnect({ projectId }),
  ], 
  transports: {
    // [mainnet.id]: http(),
    [bscTestnet.id]: http(),
    [bsc.id]: http(),
    [sepolia.id]: http(),
    [arbitrumSepolia.id]: http(),
    [base.id]: http(),
  },
});

export const TARGET_TOKEN_VALUE = 1600000000;