import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css"
import Header from "./component/layouts/Header";
import Footer from "./component/layouts/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { HashRouter as BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./component/Home/Home";
import HowItsWork from "./component/HowItsWork/HowItsWork";
// import Dashboard from "./component/Dashboard/DashboardOld";
import Dashboard from "./component/Dashboard/Dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./confiq";
import PageNotFound from "./component/layouts/PageNotFound/PageNotFound";
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from "react-loading-skeleton";

function App() {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const location = useLocation();
  const queryClient = new QueryClient();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setShowHeaderFooter(location.pathname !== "/buy");
  }, [location]);

  return (
    <>
      <SkeletonTheme baseColor="#1e685e" highlightColor="#21363d">
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          {showHeaderFooter && <Header />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/howwork" element={<HowItsWork />} />
            <Route path="/buy" element={<Dashboard />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          {showHeaderFooter && <Footer />}
        </QueryClientProvider>
      </WagmiProvider>
      </SkeletonTheme>
    </>
  );
}

function AppWithRouter() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWithRouter;
